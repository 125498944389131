import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  // {
  //   path: 'loading',
  //   loadChildren: () => import('./loading/loading.module').then(m => m.LoadingModule)
  // },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'ethera',
    loadChildren: () => import('./ethara/ethara.module').then(m => m.EtharaModule)
  },
  {
    path: 'newsroom',
    loadChildren: () => import('./newsroom/newsroom.module').then(m => m.NewsroomModule)
  },
  {
    path: 'technology',
    loadChildren: () => import('./technology/technology.module').then(m => m.TechnologyModule)
  },
  {
    path:'hyperion',
    loadChildren: () => import('./hyperion/hyperion.module').then(m => m.HyperionModule)
  },
  {
    path:'digitalBanking',
    loadChildren: () => import('./acquisition/acquisition.module').then(m => m.AcquisitionModule)
  },
  {
    path:'csr',
    loadChildren: () => import('./csr/csr.module').then(m => m.CsrModule)
  },
  {
    path:'annualReturn',
    loadChildren: () => import('./annualreturn/annualreturn.module').then(m => m.AnnualreturnModule)
  },
  {
    path: '**',
    loadChildren: () => import('./notfound/notfound.module').then(m => m.NotfoundModule)
  }




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
