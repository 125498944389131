import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals } from './app.globals';
import { configure } from "./app.configuration";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseURL: string = configure.BASE_URL;

  constructor(private _http: HttpClient, private router: Router, public globals: Globals,) { }


  postDemoRequestFormData(data) {
    var request = {
      "operationType": "SaveUserDemoRequest",
      "message": data.message,
      "mobileNumber": data.phoneNumber.toString(),
      "primaryEmail": data.email,
      "name": data.username,
      "company":data.company,
      "product":this.globals['product']
    }
    return this._http.post<any>(this.baseURL, request);
  }
  postDemoRequestOtp(data, user) {
    var request = {
      "operationType": "ValidateOTP",
      "uuid": user.uuid,
      "otp": data.emailOtp.toString(),
      "smsotp": data.mobileOtp.toString(),
    }
    return this._http.post<any>(this.baseURL, request);
  }

  postCareerFormData(data, fileName) {
    var request = {
      "operationType": "SaveUserJobRequest",
      "message": data.message,
      "mobileNumber": data.phoneNumber.toString(),
      "primaryEmail": data.email,
      "name": data.username,
      "jobtype": this.globals['careerPost'],
      "jobFile": fileName,
      "category":this.globals['category']
    }
    return this._http.post<any>(this.baseURL, request);
  }
  postCareerOtp(data, user) {
    var request = {
      "operationType": "ValidateOTPJOB",
      "uuid": user.uuid,
      "otp": data.emailOtp.toString(),
      "smsotp": data.mobileOtp.toString(),
    }
    return this._http.post<any>(this.baseURL, request);
  }

  postAllContactFormData(data) {
    var request = {
      "operationType": "SaveUserContactRequest",
      "message": data.message,
      "mobileNumber": data.phoneNumber.toString(),
      "primaryEmail": data.email,
      "name": data.username
    }
    return this._http.post<any>(this.baseURL, request);
  }
  postAllContactOtp(data, user) {
    var request = {
      "operationType": "ValidateOTPContact",
      "uuid": user.uuid,
      "otp": data.emailOtp.toString(),
      "smsotp": data.mobileOtp.toString(),
    }
    return this._http.post<any>(this.baseURL, request);
  }
  postcaseStudyRequest(data:any){
   var request = {
     "operationType": "CaseStudy",
    "company":data.company,
    "name":data.userName,
    "mobileNumber":data.phoneNumber.toString()
   }
   return this._http.post<any>(this.baseURL, request);
 }

  uploadFileToS3Service(file, fileName, fileExtension, randomNumber) {
    var url = 'https://2sjnd1yncj.execute-api.ap-south-1.amazonaws.com/prod/uploadfile?fileName=' + randomNumber + '-' + fileName + '&bucketName=' + 'splitted-bank-data-file-prod' + '&fileType=' + fileExtension
    return this._http.post(url, file, { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) });
  }

  trackClickFun(action,clickLabel){
    var request = {
      "operationType": "UserBehaviourCreditas",
      "action":action,
      "label": clickLabel,
      "path": this.router.url,
    }
    return this._http.post<any>(this.baseURL, request);
  }
}
