import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'src/app/app.globals';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-termcondition',
  templateUrl: './termcondition.component.html',
  styleUrls: ['./termcondition.component.css']
})
export class TermconditionComponent implements OnInit {

  constructor(
    public global:Globals
  ) { }

  @Input() formName:any;

  ngOnInit(): void {
    this.global.checkTnC = true
  }

  setTnCValue(value){
    this.global.checkTnC = value;
  }
}
