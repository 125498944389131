import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class Globals {

    constructor(private router: Router) { }
    public themeColor: any;
    public bgColor = '#000000';
    public color = '#ffffff';
	// public footerColor = '#030821';
	public footerColor :any;
	public desktop = false;
    public openAllContactPopup: any = false;
    public mobile:any;
    public careerPost:any = null;
	public category:any = null;
	public product:any= null;
	public checkTnC:boolean = false;
    public careersList:any = [
			// {
			// 	"name": "Operations", 
			// 	"Operations" : [
			// 		{
			// 			"post":"Credit Counsellor",
			// 			"address":"assets/pdfFiles/jdFiles/CreditCounsellor.pdf"
			// 		}
			// 	]
			// },
			// {
			// 	"name": "HR", 
			// 	"HR" : [
			// 		{
			// 			"post":"Talent Acquisition - Human Resources",
			// 			"address":"assets/pdfFiles/jdFiles/Talent_Acquisition.pdf"
			// 		}
			// 	]
			// },
			// {
			// 	"name": "Quality", 
			// 	"Quality" : [
			// 		{
			// 			"post":"Quality Analyst - Quality Assurance",
			// 			"address":"assets/pdfFiles/jdFiles/Quality_Analyst.pdf"
			// 		},
					
			// 	]
			// },
			{
				"name": "Technology", 
				"Technology" : [
					{
						"post":"Front End Software Development Engineer",
						"address":"assets/pdfFiles/jdFiles/frontendDeveloper.pdf"
					},
					{
						"post":"DevOps Engineer",
						"address":"assets/pdfFiles/jdFiles/DevopseEngineer.pdf"
					}
				]
			},
			{
				"name": "Data Science", 
				"Data Science" : [
					{
						"post":"Senior Data Analyst",
						"address":"assets/pdfFiles/jdFiles/seniorDataAnalyst.pdf"
					}
				]
			},
			{
				"name": "Product", 
				"Product" : [
			
					{
						"post":"Associate Product Manager",
						"address":"assets/pdfFiles/jdFiles/Associate_Product_Manager.pdf"
					},
					{
						"post":"Business Analyst",
						"address":"assets/pdfFiles/jdFiles/BusinessAnalyst.pdf"
					},
					{
						"post":"Marketing Associate",
						"address":"assets/pdfFiles/jdFiles/MarketingAssociate.pdf"
					},
					{
						"post":"Product Designer",
						"address":"assets/pdfFiles/jdFiles/ProductDesigner.pdf"
					},
				
				]
			},
			// {
			// 	"name": "Marketing", 
			// 	"Marketing" : [
			// 		{
			// 			"post":"Manager – Digital Marketing",
			// 			"address":"assets/pdfFiles/jdFiles/DigitalMarketing.pdf"
			// 		},
			// 		{
			// 			"post":"Manager – Public Relations",
			// 			"address":"assets/pdfFiles/jdFiles/PRManager.pdf"
			// 		},
			// 		{
			// 			"post":"Communication Strategy – Technical Writer",
			// 			"address":"assets/pdfFiles/jdFiles/TechnicalWriter.pdf"
			// 		}
			// 	]
			// }
			]

	

public awardSet:any=[
		{"name":"INNOVATION FOR FINANCIAL SECTOR ENTERPRISE","content": "Ethera, the ‘Best Innovation for the Financial Sector’ by InnTech Awards, 2021. ","link":"assets/images/newsroom/files/etheraAward2021.jpeg","awardImg":"assets/images/about/innTechAward.jpg"},
		{"name":"STARTUP OF THE YEAR 2017","content": "SILICON INDIA Startup of the year 2017","link":"assets/images/newsroom/files/StartupofTheYear-December2017-SiliconindiaMagazine.pdf","awardImg":"assets/images/about/siliconindiastartupoftheyear.png"},
		{"name":"BUSINESS WORLD TECHTORS","content": " ‘Best Company – Banking Technology'. at 'Business World Techtors'","link":"assets/images/newsroom/files/BUSINESSWORLD_Certificate.png","awardImg":"assets/images/about/BusinessWorld.png"},
]
public mediaSet:any=[
	{"name":"BUSINESS STANDARD","content": "Digital collections can slash costs by two-thirds" ,"link":"https://www.business-standard.com/article/companies/digital-collections-can-slash-costs-by-two-thirds-says-creditas-co-founder-122070300693_1.html","awardImg":"assets/images/about/BusinessStandard.png"},
	{"name":"BUSINESS STANDARD","content": "Digital collections can slash costs by two-thirds" ,"link":"assets/images/newsroom/files/BSCoverage.pdf","awardImg":"assets/images/about/BusinessStandard.png"},
	{"name":"THE ECONOMIC TIMES","content": "RBL Bank announced a partnership with Creditas Solutions for its ‘Neo Collections’ platform." ,"link":"https://economictimes.indiatimes.com/sensex-nifty-live-today-2022-02-07/liveblog/89394512.cms","awardImg":"assets/images/about/economicsTimes.png"},
	{"name":"THE TIMES OF INDIA","content": "Tech-based debt management: Way forward for banking and finance institutions in a digital world" ,"link":"https://timesofindia.indiatimes.com/blogs/voices/tech-based-debt-management-way-forward-for-banking-and-finance-institutions-in-a-digital-world/","awardImg":"assets/images/about/TheTimesOfIndia.jpg"},
	{"name":"YOUR STORY","content": "This startup’s SaaS platform eases debt collection hiccups for banks, customers" ,"link":"https://yourstory.com/2021/12/creditas-saas-platform-eases-debt-collection-startup/amp","awardImg":"assets/images/about/story.png"},
	{"name":"FINANCIAL EXPRESS","content": "Your Money: Why debt collection needs to be smart & easy" ,"link":"https://www.financialexpress.com/money/your-money-why-debt-collection-needs-to-be-smart-easy/2371951/","awardImg":"assets/images/about/financialExpress.jpg"},
	{"name":"CNBC","content": "HDFC Bank partners Creditas to help customers choose how and when to repay loans" ,"link":"https://www.cnbctv18.com/finance/hdfc-bank-partners-creditas-to-help-customers-choose-how-and-when-to-repay-loans-9893061.htm","awardImg":"assets/images/about/CNBC.jpg"},
	{"name":"THE WEEK ","content": "COVID-19 second wave may lead to rise in stress in retail segment, MSMEs" ,"link":"https://www.theweek.in/news/biz-tech/2021/06/22/covid-19-second-wave-may-lead-to-rise-in-stress-in-retail-segment-msmes.html","awardImg":"assets/images/about/theWeek.jpg"},
	{"name":"IIFL SECURITIES","content": "The reduction of income and its financial impact on the borrower will be reviewed by the financial institutions before restructuring is allowed." ,"link":"https://www.indiainfoline.com/article/editorial-interviews-leader-speak/anshuman-panwar-co-founder-creditas-solutions-121070500150_1.html","awardImg":"assets/images/about/IIFL.jpg"},
	{"name":"MINT","content": "HDFC Bank partners with Creditas on solution for loan, credit card customers" ,"link":"https://www.livemint.com/news/hdfc-bank-partners-with-creditas-on-solution-for-loan-credit-card-customers-11625564093401.html","awardImg":"assets/images/about/Mint.jpg"},
	{"name":"THE ECONOMIC TIMES","content": "Covid relief: RBI allows loan recast upto Rs 50 crore" ,"link":"assets/pdfFiles/economictimes.pdf","awardImg":"assets/images/about/economicsTimes.png"},
	{"name":"BUSINESS TODAY","content": "Unsecured personal loans, credit cards, business banking, and MSME loans are emerging as new breeding ground for banks NPAs. How serious is the problem?" ,"link":"https://www.businesstoday.in/magazine/banking/new-stress-pockets/story/437852.html","awardImg":"assets/images/about/BusinessToday.jpg"},
	{"name":"FINANCIAL EXPRESS","content": "RBI Guv Highlights: Shaktikanta Das rolls out stimulus measures amid 2nd Covid wave; liquidity, credit, more" ,"link":"https://www.financialexpress.com/economy/rbi-governor-shaktikanta-das-to-address-press-conference-soon-surprise-rate-cut-on-cards/2246029/","awardImg":"assets/images/about/financialExpress.jpg"},
	{"name":"UK ECONOMY NEWS","content": "RBI not to provide moratorium but restructuring 2.0 in place for borrowers – Check details" ,"link":"https://ukeconomynews.co.uk/rbi-not-to-provide-moratorium-but-restructuring-2-0-in-place-for-borrowers-check-details/","awardImg":"assets/images/about/UkEconomyNews.jpg"},
	{"name":"MINT","content": "Soon, you can get salary and pay EMis on bank holidays" ,"link":"https://www.livemint.com/money/personal-finance/soon-you-can-get-salary-and-pay-emis-on-bank-holidays-11623009964626.html","awardImg":"assets/images/about/Mint.jpg"},
	{"name":"FINANCIAL EXPRESS","content": "30% of pre-covid good payers who had opted for moratorium are struggling to repay now" ,"link":"https://www.financialexpress.com/money/30-of-pre-covid-good-payers-who-had-opted-for-moratorium-are-struggling-to-repay-now/2245469/","awardImg":"assets/images/about/financialExpress.jpg"},
	{"name":"THE HINDU BUSINESS LINE","content": "Creditas Solutions plans global expansion " ,"link":"assets/images/about/HinduBusinessLine.jpg","awardImg":"assets/images/about/BL.jpg"},
	{"name":"THE ECONOMIC TIMES","content": "RBI status quo on policy supportive of economic growth; liquidity measures in right direction: Experts" ,"link":"https://economictimes.indiatimes.com/industry/banking/finance/banking/rbi-status-quo-on-policy-supportive-of-economic-growth-liquidity-measures-in-right-direction-experts/articleshow/81953118.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst","awardImg":"assets/images/about/economicsTimes.png"},
	{"name":"THE TIMES OF INDIA","content": "Creditas Solutions unveils India’s first holistic digital collections and recoveries platform","link":"assets/images/about/etheraMedia.jpg","awardImg":"assets/images/about/TheTimesOfIndia.jpg"},
	{"name":"OUTLOOK INDIA","content": "SC ruling on refunding interest/penalty during moratorium may cost additional Rs 7,000-7,500 cr","link":"https://www.outlookindia.com/newsscroll/sc-ruling-on-refunding-interestpenalty-during-moratorium-may-cost-additional-rs-70007500-cr/2052285","awardImg":"assets/images/about/Outlook.jpg"}	,	
	{"name":"THE INDIAN EXPRESS","content": "Moratorium issue: Post SC verdict, banks set to classify loans of Rs 1.3 lakh crore as NPAs","link":"https://indianexpress.com/article/business/banking-and-finance/moratorium-issue-post-sc-verdict-banks-set-to-classify-loans-of-rs-1-3-lakh-crore-as-npas-7241961/","awardImg":"assets/images/about/TIE.jpg"}	,	
	{"name":"THE TIMES OF INDIA","content": "SC ruling on refunding interest, penalty during moratorium may cost additional Rs 7,000-7,500 crore","link":"https://timesofindia.indiatimes.com/business/india-business/sc-ruling-on-refunding-interest-penalty-during-moratorium-may-cost-additional-rs-7000-7500-crore/articleshow/81657139.cms#:~:text=SC%20said%20compound%20interest%20should,the%20nature%20of%20penal%20interest.","awardImg":"assets/images/about/TheTimesOfIndia.jpg"},
	{"name":"FINANCIAL EXPRESS","content": "EMI Moratorium: Are credit scores dropping as lenders start reporting non-payment to bureaus?" ,"link":"https://www.financialexpress.com/money/emi-moratorium-are-credit-scores-dropping-as-lenders-start-reporting-non-payment-to-bureaus/2238760/","awardImg":"assets/images/about/financialExpress.jpg"},
	{"name":"THE HINDU BUSINESS LINE","content":"SC ruling on loan moratorium case a ‘mixed bag’ for borrowers, lenders","link":"https://www.thehindubusinessline.com/money-and-banking/sc-ruling-on-loan-moratorium-case-a-mixed-bag-for-borrowers-lenders/article34141814.ece","awardImg":"assets/images/about/BL.jpg"},	
	{ "name":"PTI","content": "SC ruling on refunding interest/penalty during moratorium may cost additional Rs 7,000-7,500 cr","link":"https://www.outlookindia.com/newsscroll/sc-ruling-on-refunding-interestpenalty-during-moratorium-may-cost-additional-rs-70007500-cr/2052285","awardImg":"assets/images/about/PTI.jpg"},	
	{ "name":"DECCAN CHRONICLE","content": "FinTech altering India's Socio-economic landscape","link":"https://googleweblight.com/i?u=https://deccanchronicle.com/business/companies/060418/will-fintech-be-a-game-changer-in-indias-socio-economic-landscape.html&hl=en-IN","awardImg":"assets/images/about/deccanchronicle.png"},	
	{ "name":"BUSINESS STANDARD", "content": "Creditas expands its Advisory Board","link":"https://www.business-standard.com/article/news-ani/creditas-welcomes-yes-bank-s-group-president-as-advisor-118030600423_1.html","awardImg":"assets/images/about/BusinessStandard.png"},
	{ "name":"THE ECONOMIC TIMES","content": "Creditas walks out with funds in this round...","link":"assets/images/about/cEnlarge.jpg","awardImg":"assets/images/about/economicsTimes.png"},
	{ "name":"ENTREPRENEUR MAGAZINE","content": "This can really end the problems of<br>money lenders","link":"https://www.entrepreneur.com/article/311478","awardImg":"assets/images/about/EntrepreneurMagazine.png"},
	{ "name":"YOUR STORY", "content": "How fintech is helping financial institutions battle delinquency...","link":"https://yourstory.com/2018/05/fintech-helping-financial-institutions-battle-delinquency/","awardImg":"assets/images/about/story.png"},
]
public podcastSet:any=[
	{"name":"DANIEL REZEVENDOUS LIL","content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis condimentum sollicitudin dafwgw  q3rwtyerhg sfse f safefeee ","link":"","awardImg":"assets/images/newsroom/podcast1.png"},
	{"name":"HINA VASUDEVA","content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis condimentum sollicitudin. dafwgw  q3rwtyerhg sfse f safefeee","link":"","awardImg":"assets/images/newsroom/podcast2.png"},
	{"name":"SALENA MCCNARTHY","content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis condimentum sollicitudin.","link":"","awardImg":"assets/images/newsroom/podcast3.png"},
	{"name":"AURDEY SIMMOSES ","content": "dafwgw  q3rwtyerhg sfse f safefeee","link":"","awardImg":"assets/images/newsroom/podcast4.png"},
]
public bgImg:any;
public technologyImages:any=[
	'assets/images/technology/background/Technology1-min.png',
	'assets/images/technology/background/Technology2-min.png',
	'assets/images/technology/background/Technology3-min.png',
	'assets/images/technology/background/Technology4-min.png',
	'assets/images/technology/background/Technology5-min.png',
	'assets/images/technology/background/Technology6-min.png',
	'assets/images/technology/background/Technology7-min.png',
	'assets/images/technology/background/Technology8-min.png',
	'assets/images/technology/background/Technology9-min.png',
	'assets/images/technology/background/Technology10-min.png',
	'assets/images/technology/background/Technology11-min.png',
	'assets/images/technology/background/Technology12-min.png',
	'assets/images/technology/background/Technology13-min.png',
	'assets/images/technology/background/Technology14-min.png',
	'assets/images/technology/background/Technology15-min.png',
	'assets/images/technology/background/Technology16-min.png',
	'assets/images/technology/background/Technology17-min.png',
	'assets/images/technology/background/Technology18-min.png',
	'assets/images/technology/background/Technology19-min.png',
	'assets/images/technology/background/Technology20-min.png',
	'assets/images/technology/background/Technology21-min.png',	
]
public mediaScreenImg:any;
public newsroomImages:any=[
'assets/images/newsroom/mediaImg/media1.jpg',
'assets/images/newsroom/mediaImg/media2.jpg',
'assets/images/newsroom/mediaImg/media3.jpg',
'assets/images/newsroom/mediaImg/media4.jpg',
'assets/images/newsroom/mediaImg/media5.jpg',
'assets/images/newsroom/mediaImg/media6.jpg',
'assets/images/newsroom/mediaImg/media7.jpg',
'assets/images/newsroom/mediaImg/media8.jpg',
'assets/images/newsroom/mediaImg/media9.jpg',
'assets/images/newsroom/mediaImg/media10.jpg',
'assets/images/newsroom/mediaImg/media11.jpg',
'assets/images/newsroom/mediaImg/media12.jpg',
'assets/images/newsroom/mediaImg/media13.jpg',
'assets/images/newsroom/mediaImg/media14.jpg',
'assets/images/newsroom/mediaImg/media15.jpg',
'assets/images/newsroom/mediaImg/media16.jpg',
'assets/images/newsroom/mediaImg/media17.jpg',
'assets/images/newsroom/mediaImg/media18.jpg',
'assets/images/newsroom/mediaImg/media19.jpg',
'assets/images/newsroom/mediaImg/media20.jpg',
]

}