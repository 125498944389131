import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { TermconditionComponent } from './termcondition/termcondition.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TermconditionComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports:[TermconditionComponent]
})
export class SharedModule { }
