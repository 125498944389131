import { BrowserModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { Globals } from './app.globals';
import { FormsModule }   from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AuthService} from './auth.service';
import { ToastrModule } from 'ngx-toastr';
import { Angulartics2Module } from 'angulartics2';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import {SharedModule} from '../app/shared/shared.module'



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    Angulartics2Module.forRoot(),
    MatInputModule,
    MatSelectModule,
    SharedModule
 
  ],
  providers: [Globals,AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
